// import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SideBar from "./components/layout/Layout";
import Home from "./pages/Home";

import "./styles/header.css";
import "./styles/home.css";
import "./styles/footer.css";
import React  from 'react';
 
function App() {
 
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<SideBar />}>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
